import React, { useEffect, useState } from "react";
// import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { pathName } from "../../utils/pathName";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import Dragger from "../../common/Dragger";
import { useDispatch, useSelector } from "react-redux";
import { getProductFeatureContentHandler } from "../../redux/action/getProductFeatureContentSlice";
import Loader from "../../common/loader";
import GroupDragger from "../../common/groupDragger";
import { getGroupProductFeatureContentHandler } from "../../redux/action/getGroupProductFeatureContent";
import { BreadCrum } from "../../common";
import { fileUploadAction } from "../../redux/action/fileUpload";

const PlanInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  const [deleteUploadedFile, setDeleteUploadedFile] = useState("")
  const { state } = location

  const getProductFeatureContentSelector = useSelector((state) => state?.getProductFeatureContentMain)
  const getGroupProductFeatureContent = useSelector((state) => state?.getGroupProductFeatureContent)

  let getFeatureFromGroup = getGroupProductFeatureContent?.data?.data

  useEffect(() => {
    if (state?.fromGroupData?.id) {
      let payload1 = {
        groupProductFeatureId: state?.fromGroupData?.id
      }
      dispatch(getGroupProductFeatureContentHandler(payload1))
    } else {
      let payload = {
        productId: state?.productId || state?.item?.productId,
        refFeatureId: state?.refFeatureId || state?.item?.refFeatureId
      }
      dispatch(getProductFeatureContentHandler(payload))
    }
  }, [state])

  return (
    <>
      {getProductFeatureContentSelector?.isLoading === true && <Loader loaderTransForm="loaderTransForm" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Manage Product</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: getFeatureFromGroup?.groupLevelModification ? BreadCrum.GROUPS : BreadCrum.PRODUCTS, onClick: () => { navigate(getFeatureFromGroup?.groupLevelModification ? "/groups" : "/dashboard"); localStorage.removeItem("productId"); dispatch(fileUploadAction.fileUploadInfoReset()) } },
              {
                text: state?.manageProduct || getFeatureFromGroup?.groupLevelModification ? BreadCrum.MANAGE_PRODUCTS : BreadCrum.ADD_PRODUCT, onClick: () => {
                  const path = state?.manageProduct
                    ? "/edit-product"
                    : getFeatureFromGroup?.groupLevelModification
                      ? "/edit-group-product"
                      : "/addproduct";
                  dispatch(fileUploadAction.fileUploadInfoReset())
                  const navigationState = {
                    productId: state?.productId,
                    groupCode: state?.fromGroupData?.groupId
                  };
                  navigate(path, { state: navigationState });
                }
              },
              ...(getFeatureFromGroup?.groupLevelModification && getFeatureFromGroup?.productName ? [{ text: getFeatureFromGroup.productName }] : []),
              { text: BreadCrum.PLAN_INFORMATION, active: true },
            ]}
          />
          <div className="table-padding mb-30">
            <div className="headtitle justify-content-start">
              <div className="lineafter">
                <div className="productname">Product Name</div>
                <div className="producttitle">{state?.productData?.productName || getProductFeatureContentSelector?.data?.data?.productName || getGroupProductFeatureContent?.data?.data?.productName}</div>
              </div>
              <p className="mb-0 heedprodct">Plan Information</p>
            </div>
          </div>
          {state?.fromGroupData?.id ? (
            <GroupDragger deleteUploadedFile={deleteUploadedFile} setDeleteUploadedFile={setDeleteUploadedFile} state={state} />
          ) : (
            <Dragger deleteUploadedFile={deleteUploadedFile} setDeleteUploadedFile={setDeleteUploadedFile} state={state} />
          )}
        </div>
      </div>
    </>
  );
};

export default PlanInformation;
